import React, { useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import { generatePath, useNavigate } from "react-router-dom";
import Breadcrums from "../../../common/breadcrumbs";
import CustomCkeditor from "../../../common/customeditor";
import {
  CHOOSE_SIZE,
  DEFAULT_EMAIL_OPTIONS,
  SWAL_SETTINGS,
} from "../../../../../utils/Constants";
import {
  copyText,
  handleServerValidations,
} from "../../../../../utils/commonfunction";
import * as Yup from "yup";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import { NameBadgeAdd } from "../../../services/namebadge.services";
import html2pdf from 'html2pdf.js';
import ReactToPrint from 'react-to-print';

const NameBadgeTemplatesAdd = () => {
  const formikRef = useRef();
  const modalContentRef = useRef(null);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [previewimage1, setPreviewImage1] = useState("");
  const [previewimage2, setPreviewImage2] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [showBackImage, setShowBackImage] = useState(false);

  const breadcrumbs = [
    { title: "Dashboard", url: "/dashboard" },
    {
      title: "Name Badge Templates ",
      url: "/admin/cms/name-badge-templates/list/1",
    },
    { title: "Add", url: "" },
  ];

  // const [options] = React.useState(DEFAULT_EMAIL_OPTIONS).filter(option => option === 'web_url');
  // const [options] = React.useState(DEFAULT_EMAIL_OPTIONS.filter(option => option !== 'web_url' &&  option !== 'role'));
  const [options] = React.useState(DEFAULT_EMAIL_OPTIONS.filter(option => option !== 'web_url' &&  option !== 'role'));

  let dynamicOptions = [...options];

  const validationSchema = Yup.object().shape({
    size: Yup.string()
      .required("Size is required")
      .test("no-spaces", "Size is required", (value) => value.trim()),
    description_front_badge: Yup.string()
      .required("Description front badge is required")
      .test("no-spaces", "Description front badge is required", (value) =>
        value.trim()
      ),
  });

  const handleClick = (text) => {
    copyText(text);
    Swal.fire({
      icon: "success",
      text: "Copied",
      ...SWAL_SETTINGS,
    });
  };
  const getBadgeSize = () => {
    let size = formikRef?.current?.values?.size;
    if (size) {
      let [width, height] = size.split("*");
      return { width: `${width}mm`, height: `${height}mm` }
    }
    return {};
  };

  const togglePreviewImage = () => {
    let previewImage = !showBackImage
    setShowBackImage(previewImage)
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={{
          title:"",
          size: "",
          is_badge_double_sided: false,
          files1: "",
          files2: "",
          description_front_badge: "",
          description_back_badge: "",
        }}
        innerRef={formikRef}
        validateOnBlur={false}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          if (!values.title) {
            errors.title =
              "Title is required";
          }
          if (
            values.is_badge_double_sided === true ||
            values.is_badge_double_sided === "true"
          ) {
            if (!values.description_back_badge) {
              errors.description_back_badge =
                "Description back badge is required";
            }
          }
          if (
            values.is_badge_double_sided === true ||
            values.is_badge_double_sided === "true"
          ) {
            if (!values.files2) {
              errors.files2 = "This filed is required";
            } else {
              const validFormats = ["pdf", "jpeg", "png", "jpg"];
              const fileFormat = values.files2.name
                .split(".")
                .pop()
                .toLowerCase();
              if (!validFormats.includes(fileFormat)) {
                errors.files2 =
                  "Invalid file format. Accepted formats: " +
                  validFormats.join(", ");
              }
            }
          }
          if (!values.files1) {
            errors.files1 = "This filed is required";
          } else {
            const validFormats = ["pdf", "jpeg", "png", "jpg"];
            const fileFormat = values.files1.name
              .split(".")
              .pop()
              .toLowerCase();
            if (!validFormats.includes(fileFormat)) {
              errors.files1 =
                "Invalid file format. Accepted formats: " +
                validFormats.join(", ");
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitted(true);
          let formData = new FormData();
          formData.append("title", values.title);
          formData.append("size", values.size);
          formData.append(
            "is_badge_double_sided",
            values.is_badge_double_sided
          );
          formData.append("image1", values.files1);
          if (
            values.is_badge_double_sided === true ||
            values.is_badge_double_sided === "true"
          ) {
            formData.append("image2", values.files2);
          }
          formData.append(
            "description_front_badge",
            values.description_front_badge
          );
          if (
            values.is_badge_double_sided === true ||
            values.is_badge_double_sided === "true"
          ) {
            formData.append(
              "description_back_badge",
              values.description_back_badge
            );
          }
          formData.append("options", JSON.stringify(dynamicOptions));
          NameBadgeAdd(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  resetForm({ values: "" });
                  navigate(`/admin/cms/name-badge-templates/list/1`);
                }, 2000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                setSubmitted(false);
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Add Name Badge Template{" "}
                      </h6>
                    </div>
                    <div className="row row-sm">
                    <div className='col-lg-12 text-center form-group'>
                    <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                    </label>
                    <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                    <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"title"} />
                        </span>
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Choose Size:<span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="size"
                            name={"size"}
                            className="form-control select2"
                          >
                            <option value="" label="Choose size">
                              Select{" "}
                            </option>
                            {CHOOSE_SIZE.length > 0 &&
                              CHOOSE_SIZE.map((option, i) => {
                                return (
                                  <option key={i} value={option.key}>
                                    {option.label}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"size"} />
                        </span>
                      </div>
                      <div className="col-lg-12 form-group required">
                        <div className="d-flex mt-2">
                          <input
                            name="is_badge_double_sided"
                            id="is_badge_double_sided"
                            type="checkbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.is_badge_double_sided}
                          />
                          <label
                            htmlFor="is_badge_double_sided"
                            className="text-left d-flex mb-0 ms-2 no-before no-after"
                          >
                            Is Badge Double Sided
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>Is Badge Double Sided</Tooltip>}
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="files" className="text-left d-flex">
                          Upload Front Image:
                          <span className="requirestar">*</span>
                        </label>
                        <input
                          className="form-control imgInput"
                          id="files1"
                          name="files1"
                          accept="image/*"
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "files1",
                              event.currentTarget.files[0] || ""
                            );
                            event.currentTarget.files.length === 1
                              ? setPreviewImage1(
                                URL.createObjectURL(
                                  event.currentTarget.files[0]
                                )
                              )
                              : setPreviewImage1("");
                          }}
                        />
                        <span
                          className="text-danger d-flex text-left"
                          id="errortext"
                        >
                          {errors.files1 && touched.files1 && errors.files1}
                        </span>
                        {previewimage1 ? (
                          <div className="d-flex mt-2">
                            <img
                              src={previewimage1}
                              style={{ height: "100px" }}
                              alt={""}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {values.is_badge_double_sided === true ? (
                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="files2" className="text-left d-flex">
                            Upload Back Image:
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            className="form-control imgInput"
                            id="files2"
                            name="files2"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "files2",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImage2(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                )
                                : setPreviewImage2("");
                            }}
                          />
                          <span
                            className="text-danger d-flex text-left"
                            id="errortext"
                          >
                            {errors.files2 && touched.files2 && errors.files2}
                          </span>
                          {previewimage2 ? (
                            <div className="mt-2 d-flex">
                              <img
                                src={previewimage2}
                                style={{ height: "100px" }}
                                alt={""}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null}
                      <div className="col-12">
                        <label
                          htmlFor={"option" + (dynamicOptions.length - 1)}
                          className="text-left d-flex"
                        >
                          Dynamic Options:
                          <span className="text-warning ms-2">
                            (Click to copy)
                          </span>
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                Short Codes to add User Personalized Details
                                such as Name and Salutation - Click to Copy and
                                then Paste
                              </Tooltip>
                            }
                          >
                            <span className="field-more-info mt-1 ms-1 cp">
                              ?
                            </span>
                          </Whisper>
                        </label>
                      </div>
                      <div className="col-lg-12 mb-2 ">
                        {dynamicOptions &&
                          dynamicOptions.map((item, index) => (
                            <span
                              onClick={() =>
                                handleClick("{" + item.toUpperCase() + "}")
                              }
                              key={index}
                              className="badge badge-dark my-2 me-2 cp"
                            >
                              {"{" + item.toUpperCase() + "}"}
                            </span>
                          ))}
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="subject" className="text-left d-flex">
                          Description For Front Badge:
                          <span className="requirestar">*</span>
                        </label>
                        <CustomCkeditor
                          fieldname={"description_front_badge"}
                          setFieldValue={setFieldValue}
                          value={values.description_front_badge}
                          setFieldTouched={setFieldTouched}
                        />
                        <span className="text-danger d-flex text-left">
                          {errors.description_front_badge &&
                            touched.description_front_badge &&
                            errors.description_front_badge}
                        </span>
                      </div>
                      {values.is_badge_double_sided === true ? (
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            Description For Back Badge:
                            <span className="requirestar">*</span>
                          </label>
                          <CustomCkeditor
                            fieldname={"description_back_badge"}
                            setFieldValue={setFieldValue}
                            value={values.description_back_badge}
                            setFieldTouched={setFieldTouched}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.description_back_badge &&
                              touched.description_back_badge &&
                              errors.description_back_badge}
                          </span>
                        </div>
                      ) : null}
                      <div className="">
                        <button
                          className="btn ripple btn-success mr-2"
                          type="submit"
                          disabled={submitted ? true : null}
                        >
                          Save
                        </button>
                        <button
                          className="btn ripple btn-primary signbtn mr-2"
                          type="button"
                          onClick={() => setPreviewModal(true)}
                        >
                          Preview
                        </button>
                        {previewimage1 ?
                          <ReactToPrint
                            trigger={() => <Button className="btn ripple btn-secondary">Print Sample</Button>}
                            content={() => modalContentRef.current}
                          />
                          :
                          <button
                            className="btn ripple btn-danger mr-2 mr-2"
                            type="button"
                          >
                            Print Sample
                          </button>
                        }
                        <button className="btn ripple btn-secondary ms-2" type='button'  onClick={() => navigate(-1)}>
                       {/* <i className="ace-icon fa fa-times bigger-110 mx-1"></i> */}
                         Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div style={{ display: 'none' }} >
        <div ref={modalContentRef}>
          {/* Front image */}
          <div class="name-badge-container">
            {previewimage1 ?
              <div className="border p-4 rounded mx-auto" style={{ ...getBadgeSize() }}>
                <div className="name-badge-preview name-badge-centered">
                  <img src={previewimage1} className="pre-img" alt="Front Preview image" />
                  <div
                    className="name-badge-content text-white"
                    style= {{ overflow : "hidden" }}
                    dangerouslySetInnerHTML={{ __html: formikRef?.current?.values.description_front_badge ? formikRef?.current?.values.description_front_badge : null }}
                  ></div>
                </div>
              </div> : null}
            {/* Back image */}
            {previewimage2 ?
              <div className="border p-4 rounded mx-auto name-badge-below-centered" style={{ ...getBadgeSize() }}>
                <div className="name-badge-preview">
                  <img src={previewimage2} className="pre-img" alt="Back Preview image" />
                  <div
                    className="name-badge-content text-white"
                    style= {{ overflow : "hidden" }}
                    dangerouslySetInnerHTML={{ __html: formikRef?.current?.values.description_back_badge ? formikRef?.current?.values.description_back_badge : null }}
                  ></div>
                </div>
              </div> : null}
          </div>
        </div>
      </div>

      <Modal size={"lg"} open={previewModal} onClose={() => setPreviewModal(false)}>
        <Modal.Header className="mb-3">
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border p-4 rounded mx-auto" style={{ ...getBadgeSize() }}>
            <div className="name-badge-preview">
              {previewModal ? <>
                {!showBackImage ?
                  <>
                    <img src={previewimage1} className="pre-img" alt="Preview image" />
                    <div className="name-badge-content text-white"  style= {{ overflow : "hidden" }} dangerouslySetInnerHTML={{ __html: formikRef?.current?.values?.description_front_badge ? formikRef?.current?.values?.description_front_badge : null }}></div>
                  </>
                  :
                  <>
                    <img src={previewimage2} className="pre-img" alt="Preview image" />
                    <div className="name-badge-content text-white"  style= {{ overflow : "hidden" }} dangerouslySetInnerHTML={{ __html: formikRef?.current?.values?.description_back_badge ? formikRef?.current?.values?.description_back_badge : null }}></div>
                  </>
                }
              </> :
                null}
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-center">
            {formikRef?.current?.values?.is_badge_double_sided && previewimage2 ? <> <button className="btn ripple btn-danger mr-2 mr-2" type="button" onClick={togglePreviewImage}>
              Rotate Image </button></> : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setPreviewModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NameBadgeTemplatesAdd;
