import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../../common/breadcrumbs";
import { CHOOSE_SIZE, DEFAULT_EMAIL_OPTIONS, SWAL_SETTINGS } from "../../../../../utils/Constants";
import { handleServerValidations } from "../../../../../utils/commonfunction";
import * as Yup from "yup";
import { Button, Modal, Tooltip, Whisper } from "rsuite";
import { copyText } from "../../../../../utils/commonfunction";
import {
  NameBadgeDetails,
  NameBadgeEdit,
} from "../../../services/namebadge.services";
import CustomCkeditor from "../../../common/customeditor";
import html2pdf from 'html2pdf.js';
import ReactToPrint from 'react-to-print';

const NameBadgeTemplatesEdit = (props) => {
  const formikRef = useRef();
  const navigate = useNavigate();
  const modalContentRef = useRef(null);
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  // const [options] = React.useState(DEFAULT_EMAIL_OPTIONS);
  // const [options] = React.useState(DEFAULT_EMAIL_OPTIONS.filter(option => option !== 'web_url' &&  option !== 'role'));
  const [options] = React.useState(DEFAULT_EMAIL_OPTIONS.filter(option => option !== 'web_url' &&  option !== 'role'));
  const [dataLoaded, setDataLoaded] = useState(false);
  const [saveType, setSaveType] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [dynamicSize, setDynamicSize] = useState({});
  const [showBackImage, setShowBackImage] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/dashboard" },
    {
      title: "Name Badge Templates ",
      url: "/admin/cms/name-badge-templates/list/1",
    },
    { title: "Edit", url: "" },
  ];

  const [previewimage1, setPreviewImage1] = useState("");
  const [previewimage2, setPreviewImage2] = useState("");

  let dynamicOptions = [...options];

  useEffect(() => {
    NameBadgeDetails(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setPreviewImage1(response.data.front_image);
        setPreviewImage2(response.data.back_image);
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
    .required("Title is required")
    .test("no-spaces", "Title is required", (value) => value.trim()),
    size: Yup.string()
      .required("Size is required")
      .test("no-spaces", "Size is required", (value) => value.trim()),
    description_front_badge: Yup.string()
      .required("Description front badge is required")
      .test("no-spaces", "Description front badge is required", (value) =>
        value.trim()
      ),
  });

  const handleClick = (text) => {
    copyText(text);
    Swal.fire({
      icon: "success",
      text: "Copied",
      ...SWAL_SETTINGS,
    });
  };

  const getBadgeSize = (size) => {
    let data = size ? size : showdefault?.size;
    if (data) {
      let [width, height] = size.split("*");
      setDynamicSize({ width: `${width}mm`, height: `${height}mm` })
      return { width: `${width}mm`, height: `${height}mm` }
    }
    return {};
  };

  const togglePreviewImage = () => {
    let previewImage = !showBackImage
    setShowBackImage(previewImage)
  };
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={{
          title: showdefault && showdefault.title ? showdefault.title : "",
          size: showdefault && showdefault.size ? showdefault.size : "",
          is_badge_double_sided: showdefault && Boolean(showdefault.is_badge_double_sided),
          files1: showdefault && showdefault.front_image ? showdefault.front_image : "",
          files2: showdefault && showdefault.back_image ? showdefault.back_image : "",
          description_front_badge:
            showdefault && showdefault.description_front_badge
              ? showdefault.description_front_badge
              : "",
          description_back_badge:
            showdefault && showdefault.description_back_badge
              ? showdefault.description_back_badge
              : "",
        }}
        innerRef={formikRef}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          if (
            values.is_badge_double_sided === true ||
            values.is_badge_double_sided === "true"
          ) {
            if (!values.description_back_badge) {
              errors.description_back_badge =
                "Description back badge is required";
            }
          }
          if (values.is_badge_double_sided === true || values.is_badge_double_sided === "true") {
            if (!values.files2 && !values.files2.name) {
              errors.files2 = "This field is required";
            } else {
              if (typeof values.files2 != "string") {
                const fileName = values.files2.name;
                const validFormats = ["pdf", "jpeg", "png", "jpg"];
                const fileFormat = fileName.split(".").pop().toLowerCase();

                if (!validFormats.includes(fileFormat)) {
                  errors.files2 = "Invalid file format. Accepted formats: " + validFormats.join(", ");
                }
              }
            }
          }
          if (!values.files1 && !values.files1.name) {
            errors.files1 = "This field is required";
          } else {
            if (typeof values.files1 != "string") {
              const fileName = values.files1.name;
              const validFormats = ["pdf", "jpeg", "png", "jpg"];
              const fileFormat = fileName.split(".").pop().toLowerCase();

              if (!validFormats.includes(fileFormat)) {
                errors.files1 = "Invalid file format. Accepted formats: " + validFormats.join(", ");
              }
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let formData = new FormData();
          formData.append("o_id", params.id);
          formData.append("title", values.title);
          formData.append("size", values.size);
          formData.append(
            "is_badge_double_sided",
            values.is_badge_double_sided
          );
          if (values.files1 && values.files1.name) {
            formData.append("image1", values.files1);
          }
          if (values.files2 && values.files2.name) {
            formData.append("image2", values.files2);
          }
          formData.append(
            "description_front_badge",
            values.description_front_badge
          );
          if (values.is_badge_double_sided === true || values.is_badge_double_sided === "true") {
            formData.append(
              "description_back_badge",
              values.description_back_badge
            );
          }
          NameBadgeEdit(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  resetForm({ values: "" });
                  navigate(`/admin/cms/name-badge-templates/list/1`);
                }, 2000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Edit Name Badge Template{" "}
                      </h6>
                    </div>
                    <div className="row row-sm">
                    <div className='col-lg-12 text-center form-group'>
                    <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                    </label>
                    <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                    <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"title"} />
                        </span>
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Choose Size:<span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="size"
                            name={"size"}
                            className="form-control select2"
                            onChange={(e) => {
                              handleChange(e);
                              getBadgeSize(e.target.value);
                            }}
                          >
                            <option value="" label="Choose size">
                              Select{" "}
                            </option>
                            {CHOOSE_SIZE.length > 0 &&
                              CHOOSE_SIZE.map((option, i) => {
                                return (
                                  <option key={i} value={option.key}>
                                    {option.label}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"size"} />
                        </span>
                      </div>
                      <div className="col-lg-12 form-group required">
                        <div className='d-flex mt-2'>
                          <input name='is_badge_double_sided' checked={values?.is_badge_double_sided} id="is_badge_double_sided" type='checkbox' onChange={handleChange} onBlur={handleBlur} value={values.is_badge_double_sided} />
                          <label htmlFor='is_badge_double_sided' className='text-left d-flex mb-0 ms-2 no-before no-after'>Is Badge Double Sided
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Is Badge Double Sided
                            </Tooltip>}>
                              <span className='field-more-info mt-1 ms-1 cp'>?</span>
                            </Whisper>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="files" className="text-left d-flex">
                          Upload Front Image:
                          <span className="requirestar">*</span>
                        </label>
                        <input
                          className="form-control imgInput"
                          id="files1"
                          name="files1"
                          accept="image/*"
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "files1",
                              event.currentTarget.files[0] || ""
                            );
                            event.currentTarget.files.length === 1
                              ? setPreviewImage1(
                                URL.createObjectURL(
                                  event.currentTarget.files[0]
                                )
                              )
                              : setPreviewImage1("");
                          }}
                        />
                        <span
                          className="text-danger d-flex text-left"
                          id="errortext"
                        >
                          {errors.files1 && touched.files1 && errors.files1}
                        </span>
                        {previewimage1 ? (
                          <div className="mt-2 d-flex">
                            <img
                              src={previewimage1}
                              style={{ height: "100px" }}
                              alt={""}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {values.is_badge_double_sided === true ? (
                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="files2" className="text-left d-flex">
                            Upload Back Image:
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            className="form-control imgInput"
                            id="files2"
                            name="files2"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "files2",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImage2(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                )
                                : setPreviewImage2("");
                            }}
                          />
                          <span
                            className="text-danger d-flex text-left"
                            id="errortext"
                          >
                            {errors.files2 && touched.files2 && errors.files2}
                          </span>
                          {previewimage2 ? (
                            <div className="mt-2 d-flex">
                              <img
                                src={previewimage2}
                                style={{ height: "100px" }}
                                alt={""}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null}
                      <div className="col-12">
                        <label
                          htmlFor={"option" + (dynamicOptions.length - 1)}
                          className="text-left d-flex"
                        >
                          Dynamic Options:
                          <span className="text-warning ms-2">
                            (Click to copy)
                          </span>
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                Short Codes to add User Personalized Details
                                such as Name and Salutation - Click to Copy and
                                then Paste
                              </Tooltip>
                            }
                          >
                            <span className="field-more-info mt-1 ms-1 cp">
                              ?
                            </span>
                          </Whisper>
                        </label>
                      </div>
                      <div className="col-lg-12 mb-2 ">
                        {dynamicOptions &&
                          dynamicOptions.map((item, index) => (
                            <span
                              onClick={() =>
                                handleClick("{" + item.toUpperCase() + "}")
                              }
                              key={index}
                              className="badge badge-dark my-2 me-2 cp"
                            >
                              {"{" + item.toUpperCase() + "}"}
                            </span>
                          ))}
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="subject" className="text-left d-flex">
                          Description For Front Badge:
                          <span className="requirestar">*</span>
                        </label>
                        {
                          dataLoaded ?

                            <CustomCkeditor
                              fieldname={"description_front_badge"}
                              setFieldValue={setFieldValue}
                              value={values.description_front_badge}
                              setFieldTouched={setFieldTouched}
                            />
                            : null
                        }
                        <span className="text-danger d-flex text-left">
                          {errors.description_front_badge &&
                            touched.description_front_badge &&
                            errors.description_front_badge}
                        </span>
                      </div>
                      {values.is_badge_double_sided === true ? (
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            Description For Back Badge:
                            <span className="requirestar">*</span>
                          </label>
                          {dataLoaded ?
                            <CustomCkeditor
                              fieldname={"description_back_badge"}
                              setFieldValue={setFieldValue}
                              value={values.description_back_badge}
                              setFieldTouched={setFieldTouched}
                            />
                            : null
                          }
                          <span className="text-danger d-flex text-left">
                            {errors.description_back_badge &&
                              touched.description_back_badge &&
                              errors.description_back_badge}
                          </span>
                        </div>
                      ) : null}
                      <div className="">
                        <button
                          onClick={() => {
                            setSaveType("Save");
                          }}
                          className="btn btn-success mr-2"
                          type="submit"
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-primary mr-2"
                          type="button"
                          onClick={() => setPreviewModal(true)}

                        >
                          Preview
                        </button>

                        <ReactToPrint
    trigger={() => <Button className="btn ripple btn-danger">Print Sample</Button>}
    content={() => modalContentRef.current}
  />
                         <button className="btn ripple btn-secondary ms-2" type='button' onClick={() => navigate(-1)}>
                           Cancel
                           </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div style={{ display: 'none' }} >
        <div ref={modalContentRef}>
          {/* Front image */}
          <div class="name-badge-container">
            {previewimage1 ?
              <div className="border p-4 rounded mx-auto" style={dynamicSize}>
                <div className="name-badge-preview name-badge-centered">
                  <img src={previewimage1} className="pre-img" alt="Front Preview image" />
                  <div
                    className="name-badge-content text-white"  style= {{ overflow : "hidden" }}
                    dangerouslySetInnerHTML={{ __html: formikRef?.current?.values.description_front_badge ? formikRef?.current?.values.description_front_badge : null }}
                  ></div>
                </div>
              </div> : null}
            {/* Back image */}
            {previewimage2 ?
              <div className="border p-4 rounded mx-auto name-badge-below-centered mt-4" style={dynamicSize}>
                <div className="name-badge-preview">
                  <img src={previewimage2} className="pre-img" alt="Back Preview image" />
                  <div
                    className="name-badge-content text-white"  style= {{ overflow : "hidden" }}
                    dangerouslySetInnerHTML={{ __html: formikRef?.current?.values.description_back_badge ? formikRef?.current?.values.description_back_badge : null }}
                  ></div>
                </div>
              </div> : null}
          </div>
        </div>
      </div>


      <Modal size={"lg"} open={previewModal} onClose={() => setPreviewModal(false)}>
        <Modal.Header className="mb-3">
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border p-4 rounded mx-auto" style={dynamicSize}>
            <div className="name-badge-preview">
              {previewModal ? <>
                {!showBackImage ?
                  <>
                    <img src={previewimage1} className="pre-img" alt="Preview image" />
                    <div className="name-badge-content text-white"  style= {{ overflow : "hidden" }} dangerouslySetInnerHTML={{ __html:  formikRef?.current?.values.description_front_badge ?  formikRef?.current?.values.description_front_badge : null }}></div>
                  </>
                  :
                  <>
                    <img src={previewimage2} className="pre-img" alt="Preview image" />
                    <div className="name-badge-content text-white"  style= {{ overflow : "hidden" }} dangerouslySetInnerHTML={{ __html: formikRef?.current?.values.description_back_badge  ? formikRef?.current?.values.description_back_badge  : null }}></div>
                  </>
                }
              </> :
                null}
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-center">
            {showdefault.is_badge_double_sided && showdefault.back_image ? <> <button className="btn ripple btn-danger mr-2 mr-2" type="button" onClick={togglePreviewImage}>
              Rotate Image </button></> : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setPreviewModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NameBadgeTemplatesEdit;
