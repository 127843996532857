import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader';
import CustomCkeditor from '../../../common/customeditor';
import * as pageServices from "../../../services/pages.services";
import { SpecialCharacter, globalLoader, handleServerValidations } from '../../../../../utils/commonfunction';
import { CMS_PAGES_POSITIONS, FOOTER_MENUS, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { Tooltip, Whisper } from 'rsuite';




const PageEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showdefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveType, setSaveType] = useState('');


    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Pages", url: "/admin/cms/pages/list/1" }, { title: "Edit", url: "" }];

    useEffect(() => {
        pageServices.Details(params.id).then(data => {
            setShowDefault(data && data?.data ? data.data : [])
            setTimeout(() => {
                setDataLoaded(true);
            }, 100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault.title ? showdefault.title : "",
                    name: showdefault && showdefault.name ? showdefault.name : "",
                    name_de: showdefault && showdefault.name_de ? showdefault.name_de : "",
                    slug: showdefault && showdefault.slug ? showdefault.slug : "",
                    metadesciption: showdefault && showdefault.metadescription ? showdefault.metadescription : "",
                    richtext: showdefault && showdefault.description ? showdefault.description : "",
                    // type: showdefault && showdefault.type ? showdefault.type : "",
                    position: showdefault && showdefault.position ? showdefault.position : "",
                    footer_menu: showdefault && showdefault.footer_menu_name ? showdefault.footer_menu_name : "",
                    meta_tags: showdefault && showdefault.meta_tags ? showdefault.meta_tags : "",
                    meta_keyword: showdefault && showdefault.meta_keyword ? showdefault.meta_keyword : "",
                    without_login: showdefault && Boolean(showdefault.without_login),
                    generate_qr: showdefault && Boolean(showdefault.generate_qr),
                    title_de:showdefault && showdefault.title_de ? showdefault.title_de : "",
                    richtext_de:showdefault && showdefault.description_de ? showdefault.description_de : "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.title || !values.title.trim()) error.title = "Title is required";
                    if (!values.slug || !values.slug.trim()) error.slug = "Slug is required";
                    if (!values.metadesciption || !values.metadesciption.trim()) error.metadesciption = "Metadesciption is required";
                    if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                    if (values.position === 'footer') {
                        if (!values.footer_menu || !values.footer_menu.trim()) error.position = "Footer menu is required";
                    }
                    if (!values.position || !values.position.trim()) error.position = "Position is required";
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append("o_id", params.id)
                    formData.append('title', values.title);
                    formData.append('title_de', values.title_de);
                    formData.append('name', values.name);
                    formData.append('name_de', values.name_de);
                    formData.append('meta_tags', values.meta_tags);
                    formData.append('meta_keyword', values.meta_keyword);
                    formData.append('without_login', values.without_login);
                    formData.append('generate_qr', values.generate_qr);
                    formData.append('slug', values.slug);
                    formData.append('metadescription', values.metadesciption);
                    formData.append('description', values.richtext);
                    formData.append('description_de', values.richtext_de);
                    // formData.append('type', values.type);
                    if (values.position === 'footer') {
                        formData.append('footer_menu_name', values.footer_menu);
                    }
                    formData.append('position', values.position);
                    pageServices.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if(saveType !== 'Save') {
                                setTimeout(() => {
                                    resetForm({ values: '' });
                                    globalLoader(false);
                                    navigate(`/admin/cms/pages/list/1`)
                                }, 1000);
                            }
                            if(saveType === 'Save'){
                                setTimeout(() => {
                                    globalLoader(false);
                                }, 1000);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            });
                            globalLoader(false);
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Cms</h6>
                                    </div>
                                    {showdefault && Object.keys(showdefault).length > 0 ?
                                        <div className="row row-sm">
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='name' className='text-left d-flex'>Name:
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Name - Backend Only</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                    </Whisper>
                                                    </label>
                                                <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='name_de' className='text-left d-flex'>Name German:
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Name - Backend Only</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                               <input name='name_de' id="name_de" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name_de} className='form-control' />
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                    </Whisper>
                                                </label>
                                                <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title In German:
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>German Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                                <input name='title_de' id="title_de" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                                {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='slug' className='text-left d-flex'>Slug:<span className="requirestar">*</span></label>
                                                <input name='slug' id="slug" type='text' onChange={handleChange} onBlur={handleBlur} value={values.slug} className='form-control' disabled={"disabled"} />
                                                <span className='text-danger d-flex text-left'>{errors.slug && touched.slug && errors.slug}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='slug' className='text-left d-flex'>Position:<span className="requirestar">*</span></label>
                                                <div className="select-down-arrow ">
                                                    <Field as='select' id="position" name={"position"} className="form-control select2 text-capitalize">
                                                        <option value="" label="Select Position">Select{" "}</option>
                                                        {CMS_PAGES_POSITIONS && Object.values(CMS_PAGES_POSITIONS).map((option, i) => {
                                                            return (
                                                                <option key={i} value={option}>
                                                                    {SpecialCharacter(option)}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.position && touched.position && errors.position}</span>
                                            </div>
                                            {values.position && values.position === 'footer' ?
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='slug' className='text-left d-flex'>Footer Menu:<span className="requirestar">*</span></label>
                                                    <div className="select-down-arrow ">
                                                        <Field as='select' id="footer_menu" name={"footer_menu"} className="form-control select2 text-capitalize">
                                                            <option value="" label="Select Menu">Select{" "}</option>
                                                            {FOOTER_MENUS && Object.values(FOOTER_MENUS).map((option, i) => {
                                                                return (
                                                                    <option key={i} value={option}>
                                                                        {option.replace('_', ' ')}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>{errors.position && touched.position && errors.position}</span>
                                                </div> : <></>}
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='meta_tags' className='text-left d-flex'>Meta Tags:</label>
                                                <textarea name='meta_tags' id="meta_tags" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.meta_tags} className='form-control' />
                                                {/* <span className='text-danger d-flex text-left'>{errors.metadesciption && touched.metadesciption && errors.metadesciption}</span> */}
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='meta_keyword' className='text-left d-flex'>Meta Keyword:</label>
                                                <textarea name='meta_keyword' id="meta_keyword" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.meta_keyword} className='form-control' />
                                                {/* <span className='text-danger d-flex text-left'>{errors.metadesciption && touched.metadesciption && errors.metadesciption}</span> */}
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='metadesciption' className='text-left d-flex'>Meta Description (En):<span className="requirestar">*</span> </label>
                                                <textarea name='metadesciption' id="metadesciption" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.metadesciption} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.metadesciption && touched.metadesciption && errors.metadesciption}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>

                                                {
                                                    dataLoaded ?
                                                        <CustomCkeditor
                                                            fieldname={"richtext"}
                                                            setFieldValue={setFieldValue}
                                                            value={values.richtext}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                        :
                                                        ""
                                                }
                                                <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>

                                                {
                                                    dataLoaded ?
                                                        <CustomCkeditor
                                                            fieldname={"richtext_de"}
                                                            setFieldValue={setFieldValue}
                                                            value={values.richtext_de}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                        :
                                                        ""
                                                }
                                                {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                            </div>
                                            <div className='col-lg-12 form-group required'>
                                                {values.position === 'other' || values.position === 'none' ? (
                                                    <></>
                                                ) : (
                                                    <div className='d-flex'>
                                                        <input name='without_login' checked={values.without_login} id="without_login" type='checkbox' onChange={handleChange} onBlur={handleBlur} value={values.without_login} />
                                                        <label htmlFor='without_login' className='text-left d-flex mb-0 ms-2 no-before no-after'>Without Login 
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>If Page is Private (Only Visible to Logged In Users), leave empty. 
                                                        If Page is to be Public (Visible to all - regardless of login), please check the box.</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                        </Whisper> 
                                                        </label>
                                                    </div>
                                                )}
                                                 <div className='d-flex mt-2'>
                                                        <input name='generate_qr' checked={values?.generate_qr} id="generate_qr" type='checkbox' onChange={handleChange} onBlur={handleBlur} value={values.generate_qr} />
                                                        <label htmlFor='generate_qr' className='text-left d-flex mb-0 ms-2 no-before no-after'>Generated Qr 
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Generated Qr
                                                       </Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                        </Whisper> 
                                                        </label>
                                                    </div>
                                            </div>
                                            <div className="">
                                                <button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save
                                                </button>
                                                <button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save & Exit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div> : <Loader />}
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>

    )
}

export default PageEdit