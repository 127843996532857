import React, { useEffect, useState } from 'react'
import likeImage from "../../assets/user/images/thump-icon.png";
import { useSearchParams } from 'react-router-dom';

export default function Success() {
  const [message, setMessage] = useState('Success');
  const [params] = useSearchParams();
  useEffect(() => {
    try {
      const myMessage = atob(params.get('m'));
      setMessage(myMessage);
    } catch (err) {
      console.log("Invalid message");
    }
  }, []);
  
  return (
    <div>
      <div className="wraper-inner cpt bg-grey" style={{ height: "100vh" }}>
        <section className="inner-space space-b">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto thanku-card-main">
                <div className="dash-box-shadow pb-5">
                  <div className="thanku-card text-center mb-4 ">
                    <figure className="mb-3">
                      <img src={likeImage} alt="like" />
                    </figure>
                    {/* <h3 className='pt-3'>{message}</h3> */}
                    <h3 className='pt-3'>Invitation accepted successfully.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
