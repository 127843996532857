import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as questionSetServices from '../../services/questionset';
import CustomPagination from '../../common/custompagination';
import Sorting from '../../common/sorting'
import Loader from '../../common/loader'
import Breadcrums from '../../common/breadcrumbs';
import $ from 'jquery'
import {  SURVEY_TYPES, SWAL_SETTINGS, showFilterlist } from '../../../../utils/Constants';
import { capitalizeFirstLetterOfEachWord, capitalizeFirstLetter, formateDate, handleServerValidations, hasPermission, removeSpecialCharacter, SpecialCharacter, TrimText } from '../../../../utils/commonfunction';
import StatusFilter from '../../common/statusFilter';
import CustomRangepicker from '../../common/rangepicker';
import { Modal } from 'rsuite';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMove } from 'react-sortable-hoc';
import { useSelector } from 'react-redux';





const QuestionSetTable = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false")
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true)
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Question Sets", url: "" }]
    const [resetdate, setResetDate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading] = useState(false);
    const [error, setError] = useState(null);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            questionSetServices.List(formData).then(data => {
                setDataLength(data.data.total_records)
                // setSerialNo(data.data.offset)
                setList(data && data.data && data.data.list ? data.data.list : []);
                /********** MULTI SELECT >>>>>>********* */
                setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
                /********** MULTI SELECT <<<<<<********* */
                setPage(data && data.data && data.data.page ? data.data.page : 1);
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno]);


    const viewfunction = (row) => {
        navigate(`/admin/question-settings/sets/view/${row._id}?page=1&survey_page=1`)
    };

    const goToEdit = (row) => {
        navigate(`/admin/question-settings/sets/${params.pgno}/edit/${row._id}`)
    };

    const ChangeStatus = (data, Num) => {
        console.log("data", data);
        let ids = Array.isArray(data) ? data : [data];
        const formData = new FormData()
        formData.append("o_id", JSON.stringify(ids));
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                questionSetServices.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData();
                let ids = Array.isArray(data) ? data : [data];
                formdata.append("o_id", JSON.stringify(ids));
                questionSetServices.Delete(formdata).then(response => {
                    setStatusUpdate(!statsupdate);
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        /** removing deleted ids from selectedRecords */
                        setSelectedRecords((data) => data.filter((item) => { return ids.indexOf(item) === -1}));
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }


    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        // prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0;
        $("#resetQuesType")[0].selectedIndex = 0
    }

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT <<<<<<<<<********* */

    const handleQuestionsClick = (row) => {
        setError(null);
        questionSetServices.QuestionsList({ question_set_id: row._id })
            .then((response) => {
                // console.log("response", response);
                if (response?.success) {
                    setData(response?.data?.list);
                    // setData(response.data);
                    setShowModal(true);
                } else {
                    throw new Error(`API request failed with message: ${response.message}`);
                }
            })
            .catch((error) => {
                // setLoading(false);
                setError(error.message);
                console.error('Error fetching questions:', error);
            });
    };

    const handleItemReorder = (question_id, new_order) => {
        questionSetServices
            .Order({ question_id, new_order })
            .then((response) => {
                if (response?.success) {
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    })
                    const updatedOrder = response.data.data.order;
                    const updatedData = [...data];

                    const draggedQuestion = updatedData.find((item) => item._id === question_id);
                    const oldOrder = draggedQuestion.order;

                    const orderChange = updatedOrder - oldOrder;
                    updatedData.forEach((item) => {
                        if (item._id !== question_id) {
                            if (orderChange > 0 && item.order > oldOrder && item.order <= new_order) {
                                item.order -= 1;
                            } else if (orderChange < 0 && item.order >= new_order && item.order < oldOrder) {
                                item.order += 1;
                            }
                        }
                    });

                    draggedQuestion.order = new_order;

                    updatedData.sort((a, b) => a.order - b.order);

                    setData(updatedData);


                }
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    };

    const SortableItem = SortableElement(({ value }) => {
        const rowStyle = {
            cursor: "grab",
            zIndex: 99999,
        };

        const tdStyle = {
            padding: "8px 12px",
        };

        return (
            <tr style={rowStyle}>
                <td style={tdStyle}>{value.ind + 1}</td>
                <td style={tdStyle}>{value.title}</td>
                <td style={tdStyle}>
                    {value?.questionsets_data && value?.questionsets_data
                        ? value?.questionsets_data?.title
                        : "N/A"}
                </td>
                <td style={tdStyle}>{value.question_type ? SpecialCharacter(capitalizeFirstLetterOfEachWord(value.question_type)) : "N/A"}</td>
                <td style={tdStyle}>
                    {value.createdAt ? formateDate(value.createdAt) : "N/A"}
                </td>
            </tr>
        );
    });

    const SortableList = SortableContainer(({ data, onItemReorder }) => (
        <tbody>
            {data?.length > 0 ? (
                data.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={{ ...value, ind: index }}
                        onItemReorder={onItemReorder}
                    />
                ))
            ) : (
                <tr style={{ textAlign: 'center' }}>
                    <td colSpan="5">No Records</td>
                </tr>
            )}
        </tbody>
    ));

    const onSortEnd = ({ newIndex, oldIndex }) => {
        const updatedData = arrayMove(data, oldIndex, newIndex);
        setData(updatedData);
        const item = updatedData[newIndex];
        handleItemReorder(item._id, newIndex + 1);
    };

    const goToDuplicate = (row) => {
        navigate(
            `/admin/question-settings/sets/add`, { state: row }
        );
    };

    let globalData = useSelector((state) => state.globaldetail);

    const myGlobalData = useSelector((state) => state.globalData);
    const [getGlobalData, setGlobalData] = useState({});
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">Questions Sets</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div>
                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <i className="fa fa-filter mr-2 filter_icon"></i>
                                        <div className="select-down-arrow">
                                            <select className="form-control text-capitalize cp" onChange={(e) => { prepareSearch("type", e.target.value) }} id="resetQuesType">
                                                <option value="">Type</option>
                                                {SURVEY_TYPES && Object.values(SURVEY_TYPES).map((option, i) => {
                                                    return (
                                                        <option key={i} value={option}>
                                                            {removeSpecialCharacter(option)}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                {hasPermission('/admin/question-settings/sets/add') ?
                                    <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/question-settings/sets/add`)}>Add New</button>
                                    : null}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " + (hasPermission('/admin/question-settings/sets/multi-select') ? '' : "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckbox}
                                                        id="selectAllCheck"
                                                        checked={
                                                            datalength && datalength === selectedRecords.length
                                                        }
                                                        onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Type</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Category</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th>Created By</th>
                                        <th>Updated By</th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={9}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                console.log("row", row)
                                                return <tr key={i} className={isSelected(row) ? "selected" : ""}>
                                                    <td className={"position-relative " + (hasPermission('/admin/question-settings/sets/multi-select') ? '' : "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={row?._id + "input"}
                                                                    checked={isSelected(row)}
                                                                    onChange={(e) =>
                                                                        selectRecord(row, e?.target?.checked)
                                                                    }
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>

                                                    <td>{row?.title ? TrimText(row.title, 20) : "N/A"}</td>

                                                    <td className='text-capitalize'>{row.type ? removeSpecialCharacter(row.type) : "N/A"}</td>
                                                    <td>{row?.category ? SpecialCharacter(capitalizeFirstLetterOfEachWord(row?.category)) : "Default"}</td>
                                                    <td>{row?.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link> : "N/A"}</td>
                                                    <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                    {hasPermission('/admin/question-settings/sets/status') ?
                                                        <td>{row?.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                            : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive</button>}
                                                        </td>
                                                        :
                                                        <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                    }

                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>

                                                            {
                                                                row?.type === "question-set" ? (
                                                                    <>
                                                                        {hasPermission('/admin/question-settings/sets/*/edit/*/question-set') ? (
                                                                            <button
                                                                                className="btn ripple btn-success mlAction"
                                                                                onClick={() => { goToEdit(row) }}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                            <button
                                                                                className="btn ripple btn-success mlAction"
                                                                                onClick={() => { goToEdit(row) }}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                (row?.type === "question-set" || row?.type === "survey") && !(getGlobalData?.settings?.surveys === false && row?.type === "survey") ? (
                                                                    <>
                                                                        {hasPermission('/admin/question-settings/sets/delete/question-set') && (
                                                                            <button
                                                                                className="btn ripple btn-secondary mlAction"
                                                                                onClick={() => { Deletefunction(row?._id) }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                            <button
                                                                                className="btn ripple btn-secondary mlAction"
                                                                                onClick={() => { Deletefunction(row?._id) }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                row?.type === "question-set" ? (
                                                                    <>
                                                                        {hasPermission('/admin/question-settings/sets/more/question-set') ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-dark dropdown-toggle mx-1"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            >
                                                                                More
                                                                            </button>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-dark dropdown-toggle mx-1"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            >
                                                                                More
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                            <ul className="dropdown-menu">
                                                                {
                                                                row?.type === "question-set" ? (
                                                                    <>
                                                                        {hasPermission('/admin/question-settings/sets/reorder/question-set') ? (
                                                                            <li>
                                                                            <a style={{ color: "#647194" }}
                                                                            href="/"
                                                                                className="dropdown-item"
                                                                                onClick={(e) => {e.preventDefault(); handleQuestionsClick(row)}}
                                                                            >
                                                                                Reorder Questions
                                                                            </a>
                                                                        </li>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                            <li>
                                                                            <a style={{ color: "#647194" }}
                                                                            href="/"
                                                                                className="dropdown-item"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleQuestionsClick(row)}}
                                                                            >
                                                                                Reorder Questions
                                                                            </a>
                                                                        </li>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                                   {
                                                                row?.type === "question-set" ? (
                                                                    <>
                                                                       {hasPermission('/admin/question-settings/sets/newQuestions/question-set') ? (
                                                                         <li>
                                                                         <a style={{ color: "#647194" }}
                                                                         href="/"
                                                                             className="dropdown-item"
                                                                             onClick={(e) => {e.preventDefault(); navigate(`/admin/question-settings/questions/add`, { state: {question_set_id:row?._id} })}}
                                                                         >
                                                                             Add New Questions
                                                                         </a>
                                                                     </li>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                          <li>
                                                                          <a style={{ color: "#647194" }}
                                                                          href="/"
                                                                              className="dropdown-item"
                                                                              onClick={(e) => {e.preventDefault(); navigate(`/admin/question-settings/questions/add`, { state: row?._id })}}
                                                                          >
                                                                              Add New Questions
                                                                          </a>
                                                                      </li>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                                  {
                                                                row?.type === "question-set" ? (
                                                                    <>
                                                                        {hasPermission('/admin/question-settings/sets/duplicate/question-set') ? (
                                                                         <li>
                                                                         <a style={{ color: "#647194" }}
                                                                         href="/"
                                                                             className="dropdown-item"
                                                                             onClick={(e) => {e.preventDefault(); goToDuplicate(row)}}
                                                                         >
                                                                             Duplicate
                                                                         </a>
                                                                     </li>
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {!(getGlobalData?.settings?.surveys === false && row?.type === "survey") && (
                                                                          <li>
                                                                          <a style={{ color: "#647194" }} href="/"
                                                                              className="dropdown-item"
                                                                              onClick={(e) => {e.preventDefault(); goToDuplicate(row)}}
                                                                          >
                                                                              Duplicate
                                                                          </a>
                                                                      </li>
                                                                        )}
                                                                    </>
                                                                )
                                                            }
                                                            </ul>

                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={9} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Sharemedia", path: "/admin/question-settings/sets/list" }]} /> : ""}
                    </div>
                </div>
                <Modal open={showModal} size={"lg"} onClose={() => setShowModal(false)}>
                    <Modal.Header >
                        <Modal.Title>Reorder Questions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <div>Loading...</div>
                        ) : error ? (
                            <div>Error fetching questions: {error}</div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                    <thead>
                                        <tr>
                                            <th className='sr_head'>S.No</th>
                                            <th>
                                                <div className='sorting_column'>
                                                    <span>Title</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='sorting_column'>
                                                    <span>Question Set</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='sorting_column'>
                                                    <span>Question Type</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='sorting_column'>
                                                    <span>Created Date</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <SortableList data={data} setData={setData} onSortEnd={onSortEnd} />
                                </table>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
            {/* tablesection end */}
            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                                {hasPermission('/admin/question-settings/sets/status') ?

                                    <li>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedRecords, 1) }}>Active</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/question-settings/sets/status') ?

                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedRecords, 0) }}>Inactive</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/question-settings/sets/delete') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>
                                    </li>
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
        </>
    )
}

export default QuestionSetTable