import React, {useEffect, useState} from "react";
import * as otherServices from '../services/common.services';
import {attachDynamicStrings,setTitle} from '../../../utils/commonfunction'
import { useLocation } from "react-router-dom";
function NewTestPages() {
const [aboutData, setAboutData] = useState({});
const loc = useLocation();
useEffect(() => {
otherServices.cmsDetails("home_page_content").then((response) => {
setAboutData(attachDynamicStrings(response?.data?.description));
setTitle(response?.data?.title);
})
.catch((error) => {
//   console.log("error of terms&conditions",error);
})
},[loc])
return (
<>
<header className="topHeader inner-header" id="fixed-header">
   <div className="container-fluid">
      <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
         <div className="navbar-inner-box d-flex align-items-center justify-content-between">
            <div className="nav-L d-flex">
               <a className="navbar-brand desktop-view-logo" href="index.php"><img src="images/logo.png"/></a>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"><i className="ri-menu-3-line"></i>
               </span>
               </button>
               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                     <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="index.php">Home</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="#about-us">About us</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="#video-box">Survey</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="#faq">Activity Forums</a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="#testimonials"> Venues</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="nav-R ">
               <div className="dropdown profile-list-drop">
                  <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                     Lissa Watson <i className="ri-arrow-down-s-fill"></i>
                     <figure><img src="images/profile-img.png"/></figure>
                  </button>
                  <ul className="dropdown-menu">
                     <li><a className="dropdown-item" href="#">Action</a></li>
                     <li><a className="dropdown-item" href="#">Another action</a></li>
                     <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
               </div>
            </div>
         </div>
      </nav>
   </div>
</header>
<div className="cpt fiels-sec mt-5 pb-5">
   <div className="container">
      <div className="row field-one">
         <div className="col-md-12  mb-5">
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="Question 1"></input>
            </div>
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="Question 2"></input>
            </div>
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="dedicated question"></input>
            </div>
            <div className="form-group  mb-4">
               <div class="form-check black-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">select 1</label>
               </div>
               <div class="form-check black-check ">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">select 2</label>
               </div>
            </div>
            <div className="form-group mb-4">
               <textarea className="form-control signature-sec" placeholder="signature field"></textarea>
            </div>
            <div className="form-group">
               <div class="form-check black-check ">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">acceptance</label>
               </div>
            </div>
            <div className="btn-group mt-4">
               <button class="btn btn-primary">Save</button>
               <button class="btn btn-secondary ms-3">Save as draft</button>
            </div>
         </div>
      </div>
      <div className="row field-two">
         <div className="col-md-6  mb-4">
            <div class="upload-btn-wrapper mb-3">
               <button class="upload-btn">Take photo or scan QR</button>
               <input type="file" name="myfile" />
            </div>
         </div>
      </div>
      <div className="row field-three">
         <div className="col-md-12  mb-5">
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="Question 1"></input>
            </div>
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="Question 2"></input>
            </div>
            <div className="form-group  mb-4">
               <input className="form-control" placeholder="dedicated question"></input>
            </div>
            <div className="form-group  mb-4">
               <div class="form-check black-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">select 1</label>
               </div>
               <div class="form-check black-check ">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">select 2</label>
               </div>
            </div>
            <div className="form-group mb-4">
               <textarea className="form-control signature-sec" placeholder="signature field"></textarea>
            </div>
            <div className="form-group">
               <div class="form-check black-check ">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked=""></input>
                  <label class="form-check-label" for="flexCheckChecked">acceptance</label>
               </div>
            </div>
            <div className="btn-group mt-4">
               <button class="btn btn-primary">Save</button>
               <button class="btn btn-secondary ms-3">Save as draft</button>
            </div>
         </div>
      </div>
      <div className="row field-four">
         <div className="form-group col-md-6  mb-4">
            <input className="form-control" placeholder="Filter by events to which Lead coordinator is registered"></input>
         </div>
      </div>
      <div className="row">
         <div className="form-group  mb-4 col-md-4">
            <input className="form-control" placeholder="Filter by date" type="date"></input>
         </div>
         <div className="form-group  mb-4 col-md-4">
            <input className="form-control" placeholder="Filter by status (draft/completed)"></input>
         </div>
         <div className="form-group  mb-4 col-md-4">
            <input className="form-control" placeholder="Free text search"></input>
         </div>
         <div className="form-group  mb-4 col-md-12">
            <input className="form-control" placeholder="Free text search"></input>
         </div>
      </div>
   </div>
</div>
<footer className="bg-black">
   <div className="footer-box">
      <div className="container">
         <div className="row">
            <div className="col-md-4 footer-content me-auto">
               <div className="footer-top-left">
                  <figure className="mb-4"><a href="index.php"><img src="images/logo-w.png"/></a></figure>
                  <p className="pe-5">Um vendedor é uma pessoa ou empresa que vende bens ou serviços. Eles podem variar de pequenos vendedores independentes a grandes corporações. Os fornecedores podem  </p>
               </div>
            </div>
            <div className="col-md-2 footer-content">
               <h3>Company</h3>
               <ul className="footer-link">
                  <li><a href="about-us.php">About Us </a></li>
                  <li><a href="#">Survey </a></li>
                  <li><a href="#">Activity form </a></li>
               </ul>
            </div>
            <div className="col-md-2 footer-content">
               <h3>Important links</h3>
               <ul className="footer-link">
                  <li><a href="terms-&-conditions.php">Terms & Conditions</a></li>
                  <li><a href="privacy-policy.php">Privacy Policy</a></li>
                  <li><a href="faq.php">FAQ</a></li>
                  <li><a href="contact-us.php">Contact us</a></li>
               </ul>
            </div>
            <div className="col-md-3 footer-content ps-5">
               <h3>Connect with us</h3>
               <ul className="social-link">
                  <li><a className=""><i className="ri-facebook-circle-fill"></i></a></li>
                  <li><a className=""><i className="ri-instagram-line"></i></a></li>
                  <li><a className=""><i className="ri-twitter-fill"></i></a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <div className="footer-bottom">
      <div className="container">
         <div className="text-center">
            <p>© Copyright 2023 GALL All Rights Reserved </p>
         </div>
      </div>
   </div>
</footer>
</>
);
}
export default NewTestPages;