import React, { useEffect, useRef, useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import * as questionService from "../../services/questions";
import * as questionSetServices from "../../services/questionset";
import Breadcrums from "../../common/breadcrumbs";
import CustomCkeditor from "../../common/customeditor";
import { QUESTION_TYPES, SWAL_SETTINGS, CATEGORY_OPTIONS } from "../../../../utils/Constants";
import {
  handleServerValidations,
  blockInvalidCharWithNumber,
  SpecialCharacter,
  capitalizeFirstLetter,
} from "../../../../utils/commonfunction";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";



const QuestionAdd = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [options] = useState([""]);
  const [optionsDe] = useState([""]);
  const [openEmojiBox, setOpenEmojiBox] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [shouldCloseEmojiBox, setShouldCloseEmojiBox] = useState(false);
  const [showdefault, setShowDefault] = useState({})
  const [initialValues, setInitialValues] = useState({
    title: "",
    title_de: "",
    questionsetid: "",
    question_Type: "",
    options: [""],
    options_de: [""],
    emoji_options: [""],
    min_slider_value: "",
    max_slider_value: "",
    is_required: "",
    is_dedicated_questions: "",
    is_name_badge_question: "",
    richtext: "",
    richtext_de: "",
  });
  const [question_set_id] = useState(location?.state?.question_set_id ? location?.state?.question_set_id : null);

  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Question sets", url: "/admin/question-settings/sets/list/1" },
    {
      title: "View",
      url: `/admin/question-settings/sets/view/${question_set_id}?page=1&survey_page=1`,
    },
    {
      title: "Questions",
      url: `/admin/question-settings/sets/view/${question_set_id}?page=1&survey_page=1`,
    },
    { title: "Add", url: "" },
  ];
  const formikRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (openEmojiBox) {
        setShouldCloseEmojiBox(true);
      }
    }, 1000);
  }, [openEmojiBox]);

  useEffect(() => {
    setInitialValues({
      title: location?.state && location?.state?.values && location?.state?.values?.title ? location?.state?.values?.title : "",
      title_de: location?.state && location?.state?.values && location?.state?.values?.title_de ? location?.state?.values?.title_de : "",
      questionsetid: location?.state && location?.state?.values && location?.state?.values?.questionsetid ? location?.state?.values?.questionsetid : "",
      question_Type: location?.state && location?.state?.values && location?.state?.values?.question_type ? location?.state?.values?.question_type : "",
      options: location?.state && location?.state?.values && location?.state?.values?.options ? location?.state?.values?.options : [""],
      options_de: location?.state && location?.state?.values && location?.state?.values?.options_de ? location?.state?.values?.options_de : [""],
      emoji_options: location?.state && location?.state?.values && location?.state?.values?.emoji_options ? location?.state?.values?.emoji_options : "",
      min_slider_value: location?.state && location?.state?.values && location?.state?.values?.min_slider_value ? location?.state?.values?.min_slider_value : "",
      max_slider_value: location?.state && location?.state?.values && location?.state?.values?.max_slider_value ? location?.state?.values?.max_slider_value : "",
      is_required: location?.state && location?.state?.values && location?.state?.values?.is_required ? location?.state?.values?.is_required : "",
      is_dedicated_questions: location?.state && location?.state?.values && location?.state?.values?.is_dedicated_questions ? location?.state?.values?.is_dedicated_questions : "",
      is_name_badge_question: location?.state && location?.state?.values && location?.state?.values?.is_name_badge_question ? location?.state?.values?.is_name_badge_question : "",
      richtext: location?.state && location?.state?.values && location?.state?.values?.description ? location?.state?.values?.description : "",
      richtext_de: location?.state && location?.state?.values && location?.state?.values?.description_de ? location?.state?.values?.description_de : "",
    })
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    questionSetServices
      .Options()
      .then((response) => {})
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  function insertEmoji(data) {
    let olderData = formikRef?.current?.values.emoji_options;
    let handleOldData = olderData ? olderData : "";
    let newData = (handleOldData += data.native + ",");
    formikRef?.current?.setFieldValue(`emoji_options`, newData);
  }
  useEffect(() => {
    questionSetServices.Details(question_set_id).then(response => {
        setShowDefault(response && response.data ? response.data : []);
    }).catch(error => {
        console.log("Error:", error);
    });
}, [question_set_id]);

  
  function filterQuestionTypes(isDedicatedQuestion,isNameBadgeQuestion) {
    let allowedTypes = QUESTION_TYPES;
    if (showdefault.category !== CATEGORY_OPTIONS[1].value) {
      allowedTypes = Object.values(QUESTION_TYPES);
    }
    if(isDedicatedQuestion === "true" || isDedicatedQuestion === true){
      allowedTypes = [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.DROPDOWN];
    }
    if(isNameBadgeQuestion === "true" || isNameBadgeQuestion === true){
      allowedTypes = [QUESTION_TYPES.SHORT_ANS, QUESTION_TYPES.LONG_ANS];
    }
    return Object.values(allowedTypes);
  }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formikRef}
          validateOnBlur={false}
          validate={(values) => {
            const error = {};
            if (!values.title || !values.title.trim())
              error.title = "Title is required";
            if (!values.question_Type)
              error.question_Type = "Question type is required";
            if (
              values.question_Type === "single_choice" ||
              values.question_Type === "multiple_choice" ||
              values.question_Type === "dropdown"
          ) {
            if (!values.options || values.options.some(option => option.trim() === "")) {
              error.options = "Options is required";
          } else {
                  const uniqueOptions = new Set(values.options.filter(Boolean));
                  if (uniqueOptions.size !== values.options.length) {
                      error.options = error.options || "Options must be unique";
                  }
              }
          }
            if( values.question_Type === "single_choice" || values.question_Type === "multiple_choice" || values.question_Type === "dropdown") {
              if(values.options_de && values.options_de.length > 0 &&  values.options_de.some(option => option.trim() !== "")){
                const uniqueOptions = new Set(values.options_de.filter(Boolean));
                if (values.options_de && uniqueOptions.size !== values.options_de.length) {
                  error.options_de = "Options german must be unique";
                }
              }
            }
            if (values.question_Type === "slider") {
              if (!values.min_slider_value) {
                error.min_slider_value = "Min value is required";
              }
              if (!values.max_slider_value) {
                error.max_slider_value = "Max value is required";
              }
              if (values.min_slider_value && values.max_slider_value) {
                if (
                  parseInt(values.min_slider_value) >
                  parseInt(values.max_slider_value)
                ) {
                  error.min_slider_value =
                    "Min value cannot be more than max value";
                  error.max_slider_value =
                    "Max value cannot be less than min value";
                }
              }
            }
            if (values.question_Type === "emoji") {
              if (!values.emoji_options)
                error.emoji_options = "Emoji is required";
            }
            if (!values.is_required && values.question_Type !== "extra_information")
              error.is_required = "This field is required";
            if (showdefault.category === CATEGORY_OPTIONS[1].value) {
              if (!String(values.is_dedicated_questions) &&  values.question_Type !== "extra_information")
                error.is_dedicated_questions = "This filed is required";
            }
            if (!String(values.is_name_badge_question))
              {
                error.is_name_badge_question = "This filed is required";
              }
            if (!values.richtext || !values.richtext.trim())
              error.richtext = "Description is required";
            return error;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("values", values);
            setSubmitted(true);
            questionService
              .Count({ question_set_id: question_set_id })
              .then((countResponse) => {
                let formData = new FormData();
                formData.append("title", values.title);
                formData.append("title_de", values.title_de);
                formData.append("question_set_id", question_set_id);
                formData.append("question_type", values.question_Type);
                formData.append("is_required", values.is_required);
                formData.append("is_dedicated_questions", values.is_dedicated_questions);
                formData.append("is_name_badge_question", values.is_name_badge_question);
                formData.append("order", countResponse.data);
                formData.append("options", JSON.stringify(values.options));
                formData.append("options_de", JSON.stringify(values.options_de));
                formData.append("min_slider_value", values.min_slider_value);
                formData.append("max_slider_value", values.max_slider_value);
                formData.append("description", values.richtext);
                formData.append("description_de", values.richtext_de);
                if (values.question_Type === "emoji") {
                  formData.append(
                    "emoji_options",
                    JSON.stringify(
                      values.emoji_options
                        .split(",")
                        .splice(0, values.emoji_options.split(",").length - 1)
                    )
                  );
                }
                questionService
                  .Add(formData)
                  .then((response) => {
                    setSubmitting(false);
                    if (response.success) {
                      Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                      });
                      setTimeout(() => {
                        resetForm({ values: "" });
                        navigate(
                          `/admin/question-settings/sets/view/${question_set_id}?page=1&survey_page=1`
                        );
                      }, 2000);
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                      });
                      setSubmitted(false);
                    }
                  })
                  .catch((error) => {
                    console.log("error ====> ", error);
                  });
              })
              .catch((error) => {
                console.log("error=====>", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleKeyDown,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("errors", errors)}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">
                          Add Question
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            Title:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.title && touched.title && errors.title}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="title_de"
                            className="text-left d-flex"
                          >
                            Title In German:{" "}
                          </label>
                          <input
                            name="title_de"
                            id="title_de"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title_de}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                          </span>
                        </div>
                        {(values?.question_Type !== 'extra_information')&&showdefault.category === CATEGORY_OPTIONS[1].value  ? 
                          <div className='col-lg-6 text-center form-group'>
                            <label htmlFor='slug' className='text-left d-flex'>Is Dedicated Questions:<span className="requirestar">*</span></label>
                            <div className="select-down-arrow ">
                              <Field as='select' id="is_dedicated_questions" name={"is_dedicated_questions"} className="form-control select2" onChange={(e) => {
                                handleChange(e);
                              }}>
                                <option value="" label="Select">Select{" "}</option>
                                <option className="text-capitalize" value={true}>
                                  True
                                </option>
                                <option className="text-capitalize" value={false}>
                                  False
                                </option>
                              </Field>
                            </div>
                            <span className='text-danger d-flex text-left'>{errors.is_dedicated_questions && touched.is_dedicated_questions && errors.is_dedicated_questions}</span>
                          </div>
                          : null}
                           <div className='col-lg-6 text-center form-group'>
                            <label htmlFor='slug' className='text-left d-flex'>Is Name Badge Question:<span className="requirestar">*</span></label>
                            <div className="select-down-arrow ">
                              <Field as='select' id="is_name_badge_question" name={"is_name_badge_question"} className="form-control select2"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              > 
                              {values?.is_dedicated_questions === true  || values?.is_dedicated_questions === "true" ? 
                              <option className="text-capitalize" value={false}>
                              False
                            </option>
                                  :
                                <>
                                <option value="" label="Select">Select{" "}</option>
                                <option className="text-capitalize" value={true}>
                                  True
                                </option>
                                <option className="text-capitalize" value={false}>
                                  False
                                </option>
                              </>
                                 }
                              </Field>
                            </div>
                            <span className='text-danger d-flex text-left'>{errors.is_name_badge_question && touched.is_name_badge_question && errors.is_name_badge_question}</span>
                          </div>
                        <div className="col-lg-6 text-center form-group">
                          <label
                            htmlFor="question_Type"
                            className="text-left d-flex"
                          >
                            Question Type:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className="select-down-arrow">
                            <Field
                              as="select"
                              id="question_Type"
                              name={"question_Type"}
                              className="form-control select2"
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              {QUESTION_TYPES && Object.values(QUESTION_TYPES).length && 
                                filterQuestionTypes(values.is_dedicated_questions,values.is_name_badge_question).map(
                                  (option, i) => {
                                    return (
                                      <option key={i} value={option}>
                                        {SpecialCharacter(capitalizeFirstLetter(option))}
                                      </option>
                                    );
                                  }
                                )}
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.question_Type &&
                              touched.question_Type &&
                              errors.question_Type}
                          </span>
                        </div>

                        {(values?.question_Type !== QUESTION_TYPES.EXTRA_INFORMATION) ? (
                        <div className='col-lg-6 text-center form-group'>
                          <label htmlFor='slug' className='text-left d-flex'>Is Required:<span className="requirestar">*</span></label>
                          <div className="select-down-arrow ">
                            <Field
                              as="select"
                              id="is_required"
                              name={"is_required"}
                              className="form-control select2"
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              <option className="text-capitalize" value={true}>
                                True
                              </option>
                              <option className="text-capitalize" value={false}>
                                False
                              </option>
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.is_required &&
                              touched.is_required &&
                              errors.is_required}
                          </span>
                        </div>
                            ):null}
                        {values.question_Type &&
                        (values.question_Type === QUESTION_TYPES.SINGLE_CHOICE ||
                          values.question_Type === QUESTION_TYPES.MULTIPLE_CHOICE ||
                          values.question_Type === QUESTION_TYPES.DROPDOWN) ? (
                          <div className="col-lg-12 mb-2">
                            <label
                              htmlFor={"option" + (options.length - 1)}
                              className="text-left d-flex"
                            >
                              Dynamic Options:<span className="requirestar">*</span>{" "}
                            </label>
                            <FieldArray
                              name="options"
                              render={(arrayHelpers) => (
                                <div className="row">
                                  {values.options && values.options.length > 0
                                    ? values.options.map((friend, index) => (
                                        <div className="col-lg-6" key={index}>
                                          <div className="text-center form-group">
                                            <div className="d-flex">
                                              <div
                                                className="option_input"
                                                style={{ flex: 1 }}
                                                key={index}
                                              >
                                                <Field
                                                  className="form-control select2"
                                                  name={`options.${index}`}
                                                />
                                              </div>
                                              {values.options.length > 1 ? (
                                                <button
                                                  type="button"
                                                  className="ml-2 op_button danger"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  } // remove a friend from the list
                                                >
                                                  <i className="ti-close"></i>
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {index ===
                                              values.options.length - 1 ? (
                                                <button
                                                  type="button"
                                                  className="ml-2 op_button success"
                                                  onClick={() =>
                                                    arrayHelpers.push("")
                                                  }
                                                >
                                                  <i className="ti-plus"></i>
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              )}
                            />
                            <span className="text-danger d-flex text-left">
                              {errors.options &&
                                touched.options &&
                                errors.options}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {values.question_Type &&
                        (values.question_Type === QUESTION_TYPES.SINGLE_CHOICE ||
                          values.question_Type === QUESTION_TYPES.MULTIPLE_CHOICE ||
                          values.question_Type === QUESTION_TYPES.DROPDOWN) ? (
                          <div className="col-lg-12 mb-2">
                            <label
                              htmlFor={"option_de" + (optionsDe.length - 1)}
                              className="text-left d-flex"
                            >
                              Dynamic Options German:
                            </label>
                            <FieldArray
                              name="options_de"
                              render={(arrayHelpers) => (
                                <div className="row">
                                  {values.options_de && values.options_de.length > 0
                                    ? values.options_de.map((friend, index) => (
                                        <div className="col-lg-6" key={index}>
                                          <div className="text-center form-group">
                                            <div className="d-flex">
                                              <div
                                                className="option_input"
                                                style={{ flex: 1 }}
                                                key={index}
                                              >
                                                <Field
                                                  className="form-control select2"
                                                  name={`options_de.${index}`}
                                                />
                                              </div>
                                              {values.options_de.length > 1 ? (
                                                <button
                                                  type="button"
                                                  className="ml-2 op_button danger"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <i className="ti-close"></i>
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                              {index ===
                                              values.options_de.length - 1 ? (
                                                <button
                                                  type="button"
                                                  className="ml-2 op_button success"
                                                  onClick={() =>
                                                    arrayHelpers.push("")
                                                  }
                                                >
                                                  <i className="ti-plus"></i>
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              )}
                            />
                            <span className="text-danger d-flex text-left">
                              {errors.options_de &&
                                touched.options_de &&
                                errors.options_de}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {values.question_Type &&
                          (values.question_Type === QUESTION_TYPES.EMOJI ? (
                            <div className="col-lg-12 text-center form-group">
                              <label
                                htmlFor="options"
                                className="text-left d-flex"
                              >
                                Select Emoji:{" "}
                                <span className="requirestar">*</span>
                              </label>
                              <div className="d-flex">
                                <input
                                  name="emoji_options"
                                  id="emoji_options"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.emoji_options}
                                  className="form-control"
                                  onKeyDown={blockInvalidCharWithNumber}
                                />
                                <button
                                  className="chat-emoji-button"
                                  type="button"
                                  onClick={() => {
                                    setOpenEmojiBox(true);
                                  }}
                                >
                                  <i
                                    className="fa fa-smile-o emoji-btn"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                              <span className="text-danger d-flex text-left">
                                {errors.emoji_options &&
                                  touched.emoji_options &&
                                  errors.emoji_options}
                              </span>
                              {console.log(
                                "emoji_options",
                                values.emoji_options
                              )}
                              {openEmojiBox && (
                                <div className="emoji-picker-container">
                                  <Picker
                                    data={data}
                                    onEmojiSelect={insertEmoji}
                                    onClickOutside={() => {
                                      if (shouldCloseEmojiBox) {
                                        setOpenEmojiBox(false);
                                        setShouldCloseEmojiBox(false);
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          ))}
                        {values.question_Type &&
                          (values.question_Type === QUESTION_TYPES.SLIDER ? (
                            <div className="col-lg-6 text-center form-group">
                              <label
                                htmlFor="min_slider_value"
                                className="text-left d-flex"
                              >
                                Min Value:{" "}
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                name="min_slider_value"
                                id="min_slider_value"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.min_slider_value}
                                className="form-control"
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.min_slider_value &&
                                  touched.min_slider_value &&
                                  errors.min_slider_value}
                              </span>
                            </div>
                          ) : (
                            ""
                          ))}
                        {values.question_Type &&
                          (values.question_Type === QUESTION_TYPES.SLIDER ? (
                            <div className="col-lg-6 text-center form-group">
                              <label
                                htmlFor="max_slider_value"
                                className="text-left d-flex"
                              >
                                Max Value:{" "}
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                name="max_slider_value"
                                id="max_slider_value"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.max_slider_value}
                                className="form-control"
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.max_slider_value &&
                                  touched.max_slider_value &&
                                  errors.max_slider_value}
                              </span>
                            </div>
                          ) : (
                            ""
                          ))}
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            Description:<span className="requirestar">*</span>
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext"}
                            setFieldValue={setFieldValue}
                            value={values.richtext}
                            setFieldTouched={setFieldTouched}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.richtext &&
                              touched.richtext &&
                              errors.richtext}
                          </span>
                        </div>
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            Description In German:
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext_de"}
                            setFieldValue={setFieldValue}
                            value={values.richtext_de}
                            setFieldTouched={setFieldTouched}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            {errors.richtext_de &&
                              touched.richtext_de &&
                              errors.richtext_de}
                          </span> */}
                        </div>
                        <div className="">
                          <button
                            className="btn btn-main-primary signbtn mr-2"
                            type="submit"
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            Submit
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            disabled={submitted ? true : null}
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionAdd;

